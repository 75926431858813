type UseHighlightedTextReturn = (context: string) => JSX.Element[];

type HighlightOptions = {
  targets: string[];
  highlightFormat: (
    word: string,
    key: number,
    onClick?: (word: string) => void
  ) => JSX.Element;
  onClick?: (word: string) => void;
};

const useHighlightedText =
  ({
    targets,
    highlightFormat,
    onClick,
  }: HighlightOptions): UseHighlightedTextReturn =>
  (context: string) => {
    const regex = new RegExp(`(${targets.join('|')})`, 'gi');
    const parts = context.split(regex);

    return parts.map((part, index) =>
      targets.some((target) => target.toLowerCase() === part.toLowerCase()) ? (
        highlightFormat(part, index, onClick)
      ) : (
        <span key={index}>{part}</span>
      )
    );
  };

export default useHighlightedText;
